import React, { FC } from "react";
import styled from "styled-components";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { Routes } from "../models/RoutingUrls";
import { Link } from "react-router-dom";
import { showFilters } from "../states/filtersState";
import { Box, Button } from "@chakra-ui/react";
import Fab from "./Fab";

const Wrapper = styled.div`
  position: fixed;
  bottom: 80px;
  right: 10px;
  z-index: 100;
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

export const ExpensesFloatingActions: FC = () => {
    return (
        <Wrapper>
            <ButtonWrapper>
                <Fab bgColor={"teal.400"} onClick={() => showFilters(true)} icon={<FilterOutlined />} />
            </ButtonWrapper>
            <ButtonWrapper>
                <Link to={Routes.AddExpense}>
                    <Fab onClick={showFilters} icon={<PlusOutlined />} />
                </Link>
            </ButtonWrapper>
        </Wrapper>
    );
}
