import { Form, AutoComplete } from "antd";
import { useStore } from "effector-react";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { PageTitle } from "../../components/PageTitle";
import { IExpenseModel, PriceValue } from "../../models/models";
import { Routes } from "../../models/RoutingUrls";
import { ICreateExpenseArgs, IUpdateExpenseArgs, StateExpenses } from "../../states/expenseState";
import { PriceInput } from "./PriceInput";
import { $categories, loadCategoriesFx } from "../../states/categoriesState";
import { useNavigate } from "react-router-dom";
import { IExpenseState } from "../../states/types";
import { Center, Button, Heading, RadioGroup, Stack, VStack, Radio, Input, Textarea, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Flex, Select, HStack, Spacer, ButtonGroup, Box } from "@chakra-ui/react";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import CategoriesInput from "../../components/CategoriesInput";
import { useFormik } from "formik";
import Autocomplete from "react-autocomplete";
import { convertToEur } from "../../services/currency_converter";
import { $convertedToEurAmount, convertToEurFx } from "../../states/currencyConversionStates";
import debounce from "lodash.debounce";

interface IAddExpenseForm extends IExpenseModel {
    dateString?: string,
    type?: "expense" | "income";
}

interface IVm {
    editId?: string;
    expense?: IExpenseState
}

export interface Item {
    label: string;
    value: string;
}

const debouncedFilter = debounce((amount?: PriceValue) => {
    const selectedCurrency = amount?.currency || "eur";
    const enteredAmount = amount?.number || 0;

    convertToEurFx({
        amount: enteredAmount,
        currency: selectedCurrency
    });
}, 500);

export const AddExpense: FC<IVm> = ({ editId, expense }) => {
    const navigate = useNavigate();
    const convertedToEurAmount = useStore($convertedToEurAmount);
    const categories = useStore($categories);

    const isAdd = editId === undefined;
    const isEdit = !isAdd;

    useEffect(() => {
        loadCategoriesFx();
    }, []);

    const formik = useFormik<IAddExpenseForm>({
        initialValues: isAdd ?
            {
                amount: { currency: "eur", number: 0 },
                categoryName: "",
                description: "",
                //dateString: moment().date().toLocaleString(),
                type: "expense"
            } :
            {
                amount: { currency: "eur", number: expense?.amount },
                categoryName: expense?.categoryName,
                description: expense?.description,
                //dateString: editingExpense?.recordDate ? moment.utc(editingExpense.recordDate).toDate().toISOString().substr(0, 10) : dateStringNow,
                type: expense!.amount < 0 ? "expense" : "income"
            },
        onSubmit: (values) => {
            onFinish(values);
        },
        enableReinitialize: true
    });

    const isForeignCurrency = formik.values.amount?.currency !== "eur" && convertedToEurAmount !== null;

    const onFinish = async (valuesSource: IAddExpenseForm) => {
        const category: any = categories.find(x => x.name === valuesSource.categoryName);
        const values = { ...valuesSource };
        values.amount = {
            currency: valuesSource.amount?.currency,
            number: isForeignCurrency ? convertedToEurAmount : valuesSource.amount?.number,
        };
        values.description = isForeignCurrency
            ? `${valuesSource.amount?.number} ${valuesSource.amount?.currency?.toUpperCase()}${valuesSource.description && valuesSource.description.length > 0 ? "\n" + valuesSource.description : ""}`
            : valuesSource.description;

        const formDate = values.dateString;

        const expense: IExpenseModel = {
            ...values,
            date: formDate === dateStringNow ? moment.utc().toDate() : moment(formDate).utc().toDate()
        }

        console.log("Submitting date", expense.date);

        if (expense.amount?.number === undefined)
            throw Error("Amount should be a number.");

        let amount = Math.abs(expense.amount.number);
        if (values.type === "expense" && amount > 0)
            amount = -amount;

        if (isAdd) {
            const createParams: ICreateExpenseArgs = {
                amount: amount,
                category: expense.categoryName || "",
                categoryId: category?.id,
                currency: expense.amount?.currency || "",
                description: expense.description || "",
                recordedAt: expense.date || new Date()
            };

            console.log("Request", createParams);

            await StateExpenses.createExpenseFx(createParams);
        } else if (isEdit) {
            const updateParams: IUpdateExpenseArgs = {
                id: editId || "",
                amount: amount,
                category: expense.categoryName || "",
                categoryId: category?.id || null,
                currency: expense.amount?.currency || "",
                description: expense.description || "",
                recordedAt: expense.date || new Date()
            };

            await StateExpenses.updateExpenseFx(updateParams);
        }

        navigate(Routes.Expenses);
    };

    useEffect(() => {
        if (formik.values.amount?.currency !== "eur") {
            debouncedFilter(formik.values.amount);
        }
    }, [formik.values.amount]);

    const dateStringNow = moment().toDate().toISOString().substr(0, 10);

    // let CurrencyConverter = <Box w="100%">Hello</Box>;
    // const enteredAmount = formik.values.amount?.number || 0;
    const isConverterShown = formik.values.amount?.currency !== "eur";

    return (
        <Flex pb={20} direction={"column"} w="100%">
            <Center>
                <Heading size={"lg"}>{isAdd ? "Add expense" : "Edit expense"}</Heading>
            </Center>

            <Spacer />

            <form onSubmit={formik.handleSubmit}>
                <VStack>

                    <RadioGroup
                        id="type"
                        name="type"
                        value={formik.values.type}
                        onChange={(value) => formik.setFieldValue('type', value)}
                        pb={5}>
                        <Stack spacing={5} direction='row'>
                            <Radio value='expense'>
                                Expense
                            </Radio>
                            <Radio value='income'>
                                Income
                            </Radio>
                        </Stack>
                    </RadioGroup>

                    <NumberInput w={"100%"}
                        id="amount.number"
                        name="amount.number"
                        min={undefined}
                        max={undefined}
                        step={0.1}
                        precision={2}
                        pattern=".*"
                        onFocus={(e) => {
                            e.target.select();
                        }}
                        value={formik.values.amount?.number}
                        onChange={(value) => formik.setFieldValue('amount.number', value)}
                    >
                        <NumberInputField
                            placeholder='Amount' />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>

                    <Select
                        id="amount.currency"
                        name="amount.currency"
                        onChange={(e) => {
                            formik.handleChange(e);
                            // const inEur = convertToEur(formik);
                            // setForeignInEur();
                        }}
                        value={formik.values.amount?.currency}
                    >
                        <option value='eur'>EUR</option>
                        <option value='idr'>IDR</option>
                        <option value='uah'>UAH</option>
                    </Select>

                    {
                        isConverterShown && <Box w="100%" fontWeight="bold" pl="15px" fontSize={18}>{Math.round((convertedToEurAmount || 0) * 100) / 100} EUR</Box>
                    }

                    <CategoriesInput
                        id="categoryName"
                        name="categoryName"
                        onChange={(e) => {
                            formik.handleChange(e);
                        }}
                        onSelect={(value) => formik.setFieldValue('categoryName', value)}
                        value={formik.values.categoryName}
                    />

                    <Textarea
                        id="description"
                        name="description"
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        placeholder="Description" />

                    <Flex pt={10} w="100%">
                        <ButtonGroup>
                            <Button type="submit" colorScheme={"green"}>
                                {
                                    isAdd ? "Add" : "Update"
                                }
                            </Button>

                            <Button onClick={() => {
                                navigate(-1);
                            }}>
                                Cancel
                            </Button>
                        </ButtonGroup>

                        <Spacer />

                        {
                            isEdit &&
                            <Button colorScheme={"red"} onClick={async () => {
                                await StateExpenses.deleteExpenseFx(editId!);
                                //await StateExpenses.loadCurrentExpensesFx();
                                navigate(Routes.Expenses, { replace: true });
                            }}>
                                Delete
                            </Button>
                        }
                    </Flex>
                </VStack>

            </form>
        </Flex>
    );
};
