import { createEvent, createStore, restore } from "effector";
import { FilterPeriod } from "../services/api/budgetlyApiClient";
import { IFilters } from "./expenseState";

const defaultFilters: IFilters = {
    period: FilterPeriod.ThisMonth
}

export const setFilters = createEvent<IFilters>();
export const resetFilters = createEvent();
export const setFilterPeriod = createEvent<FilterPeriod>();
export const setFilterDateStart = createEvent<moment.Moment | undefined>();
export const setFilterDateEnd = createEvent<moment.Moment | undefined>();
export const setFilterCategoryId = createEvent<string | undefined>();

export const $filters = restore<IFilters>(setFilters, defaultFilters)
    .on(setFilterPeriod, (s, period) => {
        if (period !== FilterPeriod.Custom)
            return { ...s, period, dateStart: undefined, dateEnd: undefined };

        return ({ ...s, period });
    })
    .on(setFilterDateStart, (s, dateStart) => ({ ...s, dateStart }))
    .on(setFilterDateEnd, (s, dateEnd) => ({ ...s, dateEnd }))
    .on(setFilterCategoryId, (s, categoryId) => ({ ...s, categoryId }))
    .reset(resetFilters);

export const $isFiltersDefault = $filters.map(x => x === defaultFilters);

$filters.watch((e) => console.log("Filters", e))

export const showFilters = createEvent<boolean>();
export const $isFiltersShown = restore<boolean>(showFilters, false)


