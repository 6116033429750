import { Button, Col, Row } from "antd";
import React, { FC } from "react";
import { EditOutlined, InboxOutlined } from "@ant-design/icons";
import { colorExpense, colorIncome } from "../styles/theme";
import { setEditingCategoryPlan, showCategoryPlanEditor } from "../states/categoryPlanEditorState";
import { Link } from "react-router-dom";
import { Routes } from "../models/RoutingUrls";
import { $filters, setFilterCategoryId } from "../states/filtersState";
import { useStore } from "effector-react";
import { FilterPeriod } from "../services/api/budgetlyApiClient";
import { StateExpenses } from "../states/expenseState";
import { IPlannedCategory } from "../states/categoriesState";

export interface ICategoryItemVm {
    key: string;
    id: string;
    name: string;
    spent: number;
    planned: number;
    isArchived: boolean;
}

export const CategoryItem: FC<IPlannedCategory> = ({ id, name, spent, planned, isArchived }) => {
    const filters = useStore($filters);

    const plannedNormalized = planned || 0;
    const spentNormalized = spent || 0;

    const onEditCategoryPlanClick = () => {
        setEditingCategoryPlan({
            categoryId: id,
            categoryName: name,
            limit: plannedNormalized,
            isArchived: isArchived
        });
        showCategoryPlanEditor(true);
    }

    const archived = isArchived ?
        <Button shape={"circle"} size={"large"} type={"text"} disabled icon={<InboxOutlined />} />
        :
        null;

    return (
        <div key={id} style={{ marginBottom: 10, backgroundColor: isArchived ? "#a7a7a714" : "white", padding: "10px 15px", borderRadius: 10, border: "1px solid #e4e3e3" }}>
            <Row gutter={[10, 10]}>
                <Col style={{ fontSize: 16, paddingTop: 12 }}>
                    <Link to={Routes.Expenses} onClick={() => {
                        setFilterCategoryId(id);
                        StateExpenses.applyFilters();
                    }}>
                        {name}
                    </Link>
                </Col>
                <Col flex={"auto"} />
                <Col>
                    {archived}
                    <Button shape={"circle"} size={"large"} onClick={onEditCategoryPlanClick} icon={<EditOutlined />} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <span style={{
                        fontSize: 20,
                        color: spentNormalized <= plannedNormalized ? colorIncome : colorExpense
                    }}>{`${spentNormalized} EUR`}</span>
                    {
                        filters.period === FilterPeriod.ThisMonth ?
                            <>
                                <span style={{ color: "#c9c9c9" }}>&nbsp;/&nbsp;</span>
                                <span style={{}}>{`${plannedNormalized} EUR`}</span>
                            </> : " / no plan"
                    }
                </Col>
            </Row>
        </div>
    );
}
