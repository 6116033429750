import { createEffect, createEvent, createStore } from "effector";
import moment from "moment";
import { Routes } from "../models/RoutingUrls";
import { dataStorage } from "../services/data/dataStorage";
import { userService } from "../services/userService";
import { DataStorageKeys, IAuthData } from "./types";

const $userAuth = createStore<IAuthData | null>(
    dataStorage.getItem(DataStorageKeys.AuthData)
);
const logout = createEvent();

interface ILoginArgs {
    username: string;
    password: string;
}

const loginFx = createEffect({
    handler: async (args: ILoginArgs) => {
        try {
            const response = await userService.login(args.username, args.password);

            const auth: IAuthData = {
                createdAt: moment().toDate(),
                token: response.token || "",
                userId: response.user?.userId || "",
                username: response.user?.username || "",
            };

            return auth;
        } catch (error) {
            console.error("Unable to login", error);
            return null;
        }
    },
});

$userAuth
    .on(loginFx.doneData, (s, p) => (p ? { ...p } : null))
    .on(logout, (s, p) => {
        dataStorage.removeItem(DataStorageKeys.AuthData);
        window.location.pathname = Routes.Login;
        return null;
    });

loginFx.doneData.watch((x) => {
    if (x) {
        dataStorage.saveItem(DataStorageKeys.AuthData, x);
        window.location.pathname = Routes.Root;
    }
})

export const StateUser = {
    $userAuth,

    logout,

    loginFx,
};
