import { Col, Row } from "antd";
import moment from "moment";
import { utils } from "../services/utils";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../models/RoutingUrls";
import { dateTimeFormat } from "../styles/theme";
import styled from "styled-components";
import { TagOutlined } from "@ant-design/icons";
import { IExpenseState } from "../states/types";

const Wrapper = styled.div`
  background-color: white;
  border: 1px solid #e4e3e3;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 10px;

  transition: all ease-in-out 0.2s;

  &:hover {
      background-color: #f5f5f5;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const ExpenseItem: FC<IExpenseState> = ({ id, recordDate, currency, amount, categoryName, description }) => {
    return (
        <Wrapper>
            <Link to={`${Routes.EditExpense}/${id}`}>
                <div key={id}>
                    <Row style={{ margin: "5px 0" }}>
                        <Col><TagOutlined style={{ paddingRight: 5 }} />{categoryName}</Col>
                        <Col flex={"auto"}></Col>
                        <Col style={{ color: "#b6b6b6" }}>{moment(recordDate).format(dateTimeFormat)}</Col>
                    </Row>
                    <Row>
                        <Col
                            style={{ fontSize: 20 }}>
                            {utils.formatIncome(amount)}
                        </Col>
                        <Col flex={"auto"}></Col>
                        <Col style={{ margin: "5px 0" }}>{description}</Col>
                    </Row>
                    <Row>

                    </Row>
                </div>
            </Link>
        </Wrapper>
    );
}
