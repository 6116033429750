import React, { FC } from 'react';
import styled from "styled-components";
import { Route, BrowserRouter, Link, NavLink, Routes as BrowserRoutes, Navigate, Outlet } from "react-router-dom";
import { Routes } from "./models/RoutingUrls";
import ScrollToTop from "./components/ScrollToTop";
import { DashboardPage } from './pages/DashboardPage';
import { LoginPage } from './pages/Login';
import { AddExpense } from './pages/Expenses/AddExpense';
import { Expenses } from './pages/Expenses';
import { PrivateRoute } from './components/PrivateRoute';
import { CategoriesPage } from './pages/Categories';
import { EditExpense } from './pages/Expenses/EditExpense';
import { Forbidden } from "./pages/Forbidden";
import { FiltersDrawer } from "./components/FiltersDrawer";
import { CategoryPlanDrawer } from "./components/CategoryPlanDrawer";
import { Grid, Layout } from "antd";
import { useStore } from "effector-react";
import { StateUser } from "./states/userState";
import { Flex } from '@chakra-ui/react';
import Footer from './components/Footer';

// https://www.robinwieruch.de/react-router-private-routes/

const { useBreakpoint } = Grid;

const App: FC = () => {
    const screens = useBreakpoint();


    return (
        <BrowserRouter>
            {/* <ScrollToTop /> */}
            <Flex w="100%" h="100vh">
                <FiltersDrawer />
                <CategoryPlanDrawer />
                <Flex w="100%" m='15px 15px 15px 15px'>
                    <BrowserRoutes>
                        <Route path={Routes.Login} element={<LoginPage />} />
                        <Route path={Routes.Root} element={<Navigate to={Routes.Expenses} replace />} />
                        <Route element={<PrivateRoute />}>
                            <Route path={Routes.Dashboard} element={<DashboardPage />} />
                            <Route path={Routes.AddExpense} element={<AddExpense />} />
                            <Route path={Routes.EditExpense + "/:id"} element={<EditExpense />} />
                            <Route path={Routes.Expenses} element={<Expenses />} />
                            <Route path={Routes.Summary} element={<CategoriesPage />} />
                        </Route>
                        <Route path={Routes.Forbidden} element={<Forbidden />} />
                    </BrowserRoutes>
                </Flex>
                <Footer />
            </Flex>
        </BrowserRouter>
    )
};

export default App;
