import React from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { CenteredForm } from "../../components/CenteredForm";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { PageTitle } from "../../components/PageTitle";
import { StateUser } from "../../states/userState";
import { useStore } from "effector-react";
import { Routes } from "../../models/RoutingUrls";
import { Navigate } from "react-router-dom";

const layout = {

};
const tailLayout = {

};

interface IForm {
    username: string;
    password: string;
}

export const LoginPage = () => {
    const userAuth = useStore(StateUser.$userAuth);
    console.log("Auth", userAuth);

    if (userAuth)
        return <Navigate to={Routes.Dashboard} />;

    const onFinish = async (values: IForm) => {
        await StateUser.loginFx({ ...values });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const init: IForm = {
        password: "",
        username: ""
    }


    return (
        <>
            <PageTitle isCentered={true}>
                Login
            </PageTitle>
            <CenteredForm>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={init}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" style={{ width: "100%" }} htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </CenteredForm>

        </>
    );
};
