interface IAuthDataBase {
    createdAt: Date,
}
export interface IAuthData extends IAuthDataBase {
    userId: string,
    token: string,
    username: string,
}

export const DataStorageKeys = {
    AuthData: "auth_data",
}

export interface IExpenseSummary {
    expenses: IExpenseState[];
    saldo: number;
}

export interface IExpenseState {
    id: string,
    recordDate: Date,
    amount: number,
    categoryName: string,
    categoryId: string | null,
    currency: string,
    description: string
}

export interface ICategoryTotal {
    category?: string,
    total?: number;
    planned?: number;
}

export interface ICategoriesSummary {
    total: number;
    expenses: ICategoryTotal[]
}
