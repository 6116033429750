import React, { FC, useEffect, useState } from "react";
import { AutoComplete, DatePicker, Select } from "antd";
import { useStore } from "effector-react";
import {
    $filters,
    $isFiltersShown,
    resetFilters,
    setFilterCategoryId,
    setFilterDateEnd,
    setFilterDateStart,
    setFilterPeriod,
    showFilters
} from "../states/filtersState";
import styled from "styled-components";
import { FilterPeriod } from "../services/api/budgetlyApiClient";
import { StateExpenses } from "../states/expenseState";
import { $categories, ICategory, loadCategoriesFx } from "../states/categoriesState";
import { StateUser } from "../states/userState";
import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Input, Spacer, VStack } from "@chakra-ui/react";

const marginTop = "10px";

const PeriodWrapper = styled.div`
    width: 100%;

  .ant-radio-group {
    width: 100%;
  }

  .ant-radio-button-wrapper {
    display: block;
    width: 100%;
  }
`;

const CustomPeriodWrapper = styled.div`
  margin-top: ${marginTop};
  width: 100%;

  .ant-picker {
    width: 100%;
  }
`;

const CategoryFilterWrapper = styled.div`
    width: 100%;

  .ant-select {
    width: 100%;
  }
`;

export const FiltersDrawer: FC = () => {
    const user = useStore(StateUser.$userAuth);

    const filters = useStore($filters);
    const filterDateStart = filters.dateStart;
    const filterDateEnd = filters.dateEnd;
    const filterCategoryId = filters.categoryId;
    const isFiltersShown = useStore($isFiltersShown);
    const filterPeriod = filters.period;

    const categories = useStore($categories);
    const [category, setCategory] = useState<ICategory>();


    useEffect(() => {
        if (user !== null) {
            const category = categories.find(x => x.id === filterCategoryId);
            setCategory(category);
        }
    }, [filterCategoryId]);

    const shouldShowFilterRange = filterPeriod === FilterPeriod.Custom;

    useEffect(() => {
        if (user !== null) {
            loadCategoriesFx();
            console.log(user);
        }
    }, [user]);

    const onFiltersClose = () => {
        showFilters(false);
    }

    const onStartDateSelected = (date: moment.Moment) => {
        const start = date.startOf("day");
        setFilterDateStart(start);
    }

    const onEndDateSelected = (date: moment.Moment) => {
        const end = date.endOf("day");
        setFilterDateEnd(end);
    }

    const onCategorySelected = (name: string) => {
        const foundCategory = categories.find(x => x.name === name);
        if (!foundCategory || !foundCategory.id)
            return;

        setFilterCategoryId(foundCategory.id);
    };

    const onCategoryReset = () => {
        setFilterCategoryId(undefined);
    };

    const onApplyFilters = async () => {
        await StateExpenses.applyFilters();
        showFilters(false);
    }

    const onResetFilters = async () => {
        resetFilters();
        await StateExpenses.applyFilters();
        showFilters(false);
    }



    return (<>
        <Drawer
            isOpen={isFiltersShown}
            placement='bottom'
            onClose={onFiltersClose}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Filter expenses</DrawerHeader>

                <DrawerBody >
                    <VStack>
                        <PeriodWrapper>
                            <Select
                                size={"middle"}
                                style={{ width: "100%" }}
                                placeholder="Select time period"
                                value={filterPeriod}
                                onSelect={(e: FilterPeriod) => {
                                    setFilterPeriod(e);
                                }}
                            >
                                <Select.Option value={FilterPeriod.ThisMonth}>This month</Select.Option>
                                <Select.Option value={FilterPeriod.LastMonth}>Last month</Select.Option>
                                <Select.Option value={FilterPeriod.ThisWeek}>This week</Select.Option>
                                <Select.Option value={FilterPeriod.LastWeek}>Last week</Select.Option>
                                <Select.Option value={FilterPeriod.ThisDay}>Today</Select.Option>
                                <Select.Option value={FilterPeriod.Yesterday}>Yesterday</Select.Option>
                                <Select.Option value={FilterPeriod.Custom}>Custom</Select.Option>
                                <Select.Option value={FilterPeriod.Everything}>Everything</Select.Option>
                            </Select>
                        </PeriodWrapper>

                        {
                            shouldShowFilterRange &&
                            <>
                                <CustomPeriodWrapper>
                                    <DatePicker value={filterDateStart} onSelect={onStartDateSelected} placeholder={"Start date"} />
                                </CustomPeriodWrapper>
                                <CustomPeriodWrapper>
                                    <DatePicker value={filterDateEnd} onSelect={onEndDateSelected} placeholder={"End date"} />
                                </CustomPeriodWrapper>
                            </>
                        }

                        <CategoryFilterWrapper style={{ marginTop: shouldShowFilterRange ? "20px" : marginTop }}>
                            <AutoComplete
                                placeholder="Filter category"
                                onSelect={onCategorySelected}
                                onClear={onCategoryReset}
                                value={category?.name}
                                onChange={(e) => {
                                    setCategory({ ...category, name: e, })
                                }}
                                allowClear={true}
                                filterOption={(inputValue, option) =>
                                    option!.value?.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }>
                                {
                                    categories.map(x => {
                                        return (<AutoComplete.Option key={x.name} value={x.name!}>
                                            {x.name}
                                        </AutoComplete.Option>);
                                    })
                                }
                            </AutoComplete>
                        </CategoryFilterWrapper>
                    </VStack>
                </DrawerBody>

                <DrawerFooter>
                    <Flex w="100%">
                        <Button variant='outline' onClick={onResetFilters}>
                            Reset
                        </Button>
                        <Spacer />
                        <Button colorScheme='blue' onClick={onApplyFilters}>
                            Apply
                        </Button>
                    </Flex>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>

        {/* <Drawer
            height={shouldShowFilterRange ? 400 : 300}
            title="View Settings"
            placement={"bottom"}
            onClose={onFiltersClose}
            visible={isFiltersShown}
            closable={true}>

            <PeriodWrapper>
                <Select
                    size={"middle"}
                    style={{ width: "100%" }}
                    placeholder="Select time period"
                    value={filterPeriod}
                    onSelect={(e: FilterPeriod) => {
                        setFilterPeriod(e);
                    }}
                >
                    <Select.Option value={FilterPeriod.ThisMonth}>This month</Select.Option>
                    <Select.Option value={FilterPeriod.LastMonth}>Last month</Select.Option>
                    <Select.Option value={FilterPeriod.ThisWeek}>This week</Select.Option>
                    <Select.Option value={FilterPeriod.LastWeek}>Last week</Select.Option>
                    <Select.Option value={FilterPeriod.ThisDay}>Today</Select.Option>
                    <Select.Option value={FilterPeriod.Yesterday}>Yesterday</Select.Option>
                    <Select.Option value={FilterPeriod.Custom}>Custom</Select.Option>
                    <Select.Option value={FilterPeriod.Everything}>Everything</Select.Option>
                </Select>
            </PeriodWrapper>

            {
                shouldShowFilterRange &&
                <>
                    <CustomPeriodWrapper>
                        <DatePicker value={filterDateStart} onSelect={onStartDateSelected} placeholder={"Start date"} />
                    </CustomPeriodWrapper>
                    <CustomPeriodWrapper>
                        <DatePicker value={filterDateEnd} onSelect={onEndDateSelected} placeholder={"End date"} />
                    </CustomPeriodWrapper>
                </>
            }

            <CategoryFilterWrapper style={{ marginTop: shouldShowFilterRange ? "20px" : marginTop }}>
                <AutoComplete
                    placeholder="Filter category"
                    onSelect={onCategorySelected}
                    onClear={onCategoryReset}
                    value={category?.name}
                    onChange={(e) => {
                        setCategory({ ...category, name: e, })
                    }}
                    allowClear={true}
                    filterOption={(inputValue, option) =>
                        option!.value?.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }>
                    {
                        categories.map(x => {
                            return (<AutoComplete.Option key={x.name} value={x.name!}>
                                {x.name}
                            </AutoComplete.Option>);
                        })
                    }
                </AutoComplete>
            </CategoryFilterWrapper>

            <Button type={"primary"} onClick={onApplyFilters} style={{ marginTop: "20px", width: "100%" }}>
                Apply
            </Button>
            <Button type={"default"} onClick={onResetFilters} style={{ marginTop: "20px", width: "100%" }}>
                Reset
            </Button>
        </Drawer> */}
    </>
    );
}
