import config from "../config"
import { ApiClient } from "./api/apiClient"
import { Routes } from "../models/RoutingUrls";

const wrap = <T>(method: Promise<T>): Promise<T> => {
    return method.then((e) => {
        console.error("API request error", e);
        window.location.pathname = Routes.Login;

        return e;
    });
}

export const userService = {
    login: (username: string, password: string) => {
        return ApiClient.user_authenticate(config.appVersion, {
            password: password,
            usernameOrEmail: username
        });
    }
}
