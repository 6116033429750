import { useStore } from "effector-react";
import React, { useEffect, useState } from "react";
import { PageTitle } from "../../components/PageTitle";
import moment from "moment";
import { CategoryItem } from "../../components/CategoryItem";
import { TotalSummary } from "../../components/TotalSummary";
import { CategoriesFloatingActions } from "../../components/CategoriesFloatingActions";
import {
    $plannedCategories,
    IPlannedCategory,
    loadCategoriesFx
} from "../../states/categoriesState";
import { Box } from "@chakra-ui/react";

export const CategoriesPage = (): JSX.Element => {
    const format = "YYYY-MM";

    const plannedCategories = useStore($plannedCategories);
    const [displayDate, setDisplayDate] = useState<moment.Moment>(moment.utc());

    useEffect(() => {
        loadCategoriesFx();
    }, []);

    return (
        <div>
            <CategoriesFloatingActions />
            <TotalSummary />
            <PageTitle>Categories</PageTitle>

            <Box pb={10}>
                {plannedCategories.map((item: IPlannedCategory) => <CategoryItem key={item.id} {...item} />)}
            </Box>
        </div>
    );
};
