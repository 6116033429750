import React, {FC} from "react";
import styled from "styled-components";
import {Button} from "antd";
import {FilterOutlined, PlusOutlined} from "@ant-design/icons";
import {Routes} from "../models/RoutingUrls";
import {Link} from "react-router-dom";
import {createCategoryFx} from "../states/categoriesState";

const Wrapper = styled.div`
  position: fixed;
  bottom: 80px;
  right: 30px;
  z-index: 100;
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

export const CategoriesFloatingActions: FC = () => {

    const addCategory = async () => {
        await createCategoryFx();
    };

    return (
        <Wrapper>
            <ButtonWrapper>
                    <Button
                        onClick={addCategory}
                        size={"large"}
                        type={"default"}
                        style={{
                            width: 50,
                            height: 50,
                            backgroundColor: "#88d34c",
                            color: "white"
                        }} shape="circle" icon={<PlusOutlined/>}/>
            </ButtonWrapper>
        </Wrapper>
    );
}
