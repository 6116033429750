import { FilterOutlined } from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import React, { FC } from "react";
import { showFilters } from "../states/filtersState";

interface FabProps {
    icon: any,
    onClick?: () => void,
    color?: string
    bgColor?: string
}

const Fab: FC<FabProps> = ({ icon, onClick, color = "white", bgColor = "green.400" }) => {
    return (
        <Box
            bgColor={bgColor}
            p={4}
            borderRadius="50%"
            color={color}
            h="55px"
            w="55px"
            as="button"
            onClick={() => {
                onClick && onClick();
            }}
        >
            {icon}
        </Box>
    );
}

export default Fab;
