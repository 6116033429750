import {restore, createEvent, createEffect} from "effector";
import {expenseService} from "../services/expenseService";

export interface ICategoryPlan {
    categoryId: string;
    categoryName: string;
    limit: number;
    isArchived: boolean;
}

export const saveCategoryPlanFx = createEffect({
    handler: async ({ categoryId, limit, isArchived }: ICategoryPlan) => {
        try {
            const response = await expenseService.saveCategoryPlan(categoryId, limit);
            if (!response.ok || !response.data)
                throw Error("Unable to save category plan.");

            return response.data;
        } catch (error) {
            console.error("Error occurred while saving category plan.", error);
            return [];
        }
    }
})

export const showCategoryPlanEditor = createEvent<boolean>();
export const $isCategoryPlanEditorShown = restore(showCategoryPlanEditor, false);

export const setEditingCategoryPlan = createEvent<ICategoryPlan | null>();
export const $editingCategoryPlan = restore(setEditingCategoryPlan, null);
