import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { Routes } from "../../models/RoutingUrls";

export const DashboardPage = (): JSX.Element => {
    return (
        <div>
            <PageTitle>Dashboard</PageTitle>
            <Link to={Routes.AddExpense}>
                <Button>Add Expense</Button>
            </Link>
        </div>);
};
