import config from "../config"
import { ApiClient } from "./api/apiClient"
import { FilterPeriod } from "./api/budgetlyApiClient";
import { generateName } from "./name";

export const expenseService = {
    getCurrent: (period: FilterPeriod, categoryId?: string, utcStart?: Date, utcEnd?: Date) => {
        return ApiClient.expenses_getCurrentExpenses(categoryId, period, utcStart, utcEnd, config.appVersion);
    },
    create: (amount: number, currency: string, category: string, categoryId: string | undefined, description: string, recordedAt: Date) => {
        return ApiClient.expenses_createExpense(config.appVersion, {
            amount,
            currency,
            category,
            description,
            categoryId: categoryId || undefined,
            utcRecorded: recordedAt
        });
    },
    update: (id: string, amount: number, currency: string, category: string, description: string, recordedAt: Date) => {
        return ApiClient.expenses_updateExpense(id, config.appVersion, {
            amount,
            currency,
            category,
            description,
            utcRecorded: recordedAt
        });
    },
    getCategories: (month: Date | null) => {
        return ApiClient.categories_getCategoryWithPlans(month || undefined, config.appVersion);
    },
    updateCategory: (id: string, name: string, isArchived: boolean) => {
        return ApiClient.categories_updateCategory(id, config.appVersion, {
            name: name,
            isArchived: isArchived
        });
    },
    createCategory: () => {
        return ApiClient.categories_createCategory(`-${generateName()}`, false, config.appVersion);
    },
    deleteCategory: (id: string) => {
        return ApiClient.categories_deleteCategory(id, config.appVersion);
    },
    saveCategoryPlan: (categoryId: string, limit: number, month?: Date) => {
        return ApiClient.categoryPlan_createExpensePlan(config.appVersion, {
            categoryId: categoryId,
            month: month,
            limit: limit
        });
    },
    getExpense: (id: string) => {
        return ApiClient.expenses_getExpense(id, config.appVersion);
    },
    deleteExpense: (id: string) => {
        return ApiClient.expenses_deleteExpense(id, config.appVersion);
    }
}
