const saveItem = <T>(key: string, data: T) => {
    const json = JSON.stringify(data);
    localStorage.setItem(key, json);
};

const getItem = <T>(key: string): T | null => {
    try {
        const json = localStorage.getItem(key);
        if (json === null)
            return null;

        return JSON.parse(json);
    } catch (e) {
        console.error("Unable to deserialize item from JSON.", e);
        return null;
    }
};

const removeItem = <T>(key: string): T | null => {
    try {
        const item = localStorage.getItem(key);
        if (item === null)
            return item;
        localStorage.removeItem(key);

        return JSON.parse(item);
    }
    catch (e) {
        console.error("Unable to remove item.", e);
        return null;
    }
}

export const dataStorage = {
    saveItem,
    getItem,
    removeItem
};
