import { Divider } from "antd";
import React, { FC } from "react";
import styled from "styled-components";
import { IPropsBase } from "../types";

const TitleWrapper = styled.div<IVm>`
    font-size: 23px;
    text-align: ${({ isCentered = false }) => isCentered ? "center" : "left"};
`;

interface IVm extends IPropsBase {
    isCentered?: boolean;
}

export const PageTitle: FC<IVm> = ({ children, isCentered = false }) => {
    return (
        <TitleWrapper isCentered={isCentered}>
            {children}
            <Divider />
        </TitleWrapper>
    );
}
