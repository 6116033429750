import {InputNumber, Select} from "antd";
import React, {FC, useState} from "react";
import {PriceValue, Currency} from "../../models/models";


interface PriceInputProps {
    value?: PriceValue;
    onChange?: (value: PriceValue) => void;
    showCurrency?: boolean;
}

export const PriceInput: FC<PriceInputProps> = ({value = {}, onChange, showCurrency = true}) => {
    const [number, setNumber] = useState(0.00);
    const [currency, setCurrency] = useState<Currency>('eur');

    const triggerChange = (changedValue: { number?: number; currency?: Currency }) => {
        onChange?.({number, currency, ...value, ...changedValue});
    };

    const onNumberChange = (value: any) => {
        const newNumber = parseFloat(value || '0.00');
        triggerChange({number: newNumber});
        setNumber(value);
    };

    const onCurrencyChange = (newCurrency: Currency) => {
        if (!('currency' in value)) {
            setCurrency(newCurrency);
        }
        triggerChange({currency: newCurrency});
    };

    return (
        <span>
            <InputNumber
                type="number"
                value={value.number !== undefined ? value.number : number}
                onChange={onNumberChange}
                precision={2}
                onFocus={(e) => {
                    e.target.select();
                }}
                step="0.01"
            />
            {
                showCurrency &&
                <Select
                    value={value.currency || currency}
                    style={{width: 80, margin: '0 8px'}}
                    onChange={onCurrencyChange}
                >
                    <Select.Option value="eur">EUR</Select.Option>
                </Select>
            }
        </span>
    );
};
