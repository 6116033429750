import { useStore } from "effector-react";
import React from "react";
import { Navigate, Route, RouteProps } from 'react-router';
import { Outlet } from "react-router-dom";
import { Routes } from "../models/RoutingUrls";
import { StateUser } from "../states/userState";

export const PrivateRoute = ({ redirectPath = Routes.Login }) => {
    const userAuth = useStore(StateUser.$userAuth);

    if (userAuth) {
        return <Outlet />;
    } else {
        return <Navigate to={redirectPath} replace />;
    }
};