import { createStore } from 'effector';
import { createEffect } from 'effector';
import { currenciesService } from '../services/currenciesService';

interface IConvertToEurParams {
    currency: string,
    amount: number
}

export const $convertedToEurAmount = createStore<number | null>(null);

export const convertToEurFx = createEffect({
    handler: async ({ currency, amount }: IConvertToEurParams) => {
        const converted = await currenciesService.convertToEur(amount, currency);
        return converted.data || null;
    }
})

$convertedToEurAmount.on(convertToEurFx.doneData, (s, p) => p);
