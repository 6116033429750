import React, { FC } from "react";
import styled from "styled-components";
import { utils } from "../services/utils";
import { useStore } from "effector-react";
import { $filters, $isFiltersDefault } from "../states/filtersState";
import { dateTimeFormat } from "../styles/theme";
import { FilterPeriod } from "../services/api/budgetlyApiClient";
import moment from "moment";
import { StateExpenses } from "../states/expenseState";
import { $categoriesTotalPlanned, $categoriesTotalSpent } from "../states/categoriesState";
import { Affix, message } from "antd";

const Wrapper = styled.div`
`;

const TotalSumWrapper = styled.div`
  font-size: 30px;
`;

const PeriodWrapper = styled.div`
  font-size: 15px;
`;

export const TotalSummary: FC = () => {
    const categoriesTotalPlanned = useStore($categoriesTotalPlanned);
    const isFiltersDefault = useStore($isFiltersDefault);
    const categoriesTotalSpent = useStore($categoriesTotalSpent);
    const totalSum = -useStore(StateExpenses.$saldo);
    const filters = useStore($filters);
    const filterPeriod = filters.period;

    const selectedStartDate = filters.dateStart;
    const selectedEndDate = filters.dateEnd;

    let periodContent;

    if (filterPeriod === FilterPeriod.Custom && selectedStartDate && selectedEndDate) {
        periodContent = `${selectedStartDate.format(dateTimeFormat)} - ${selectedEndDate.format(dateTimeFormat)}`;
    } else if (filterPeriod === FilterPeriod.ThisDay) {
        periodContent = "Today";
    } else if (filterPeriod === FilterPeriod.Yesterday) {
        periodContent = "Yesterday";
    } else if (filterPeriod === FilterPeriod.ThisWeek) {
        periodContent = "This week";
    } else if (filterPeriod === FilterPeriod.LastWeek) {
        periodContent = "Last week";
    } else if (filterPeriod === FilterPeriod.LastMonth) {
        periodContent = "Last month";
    } else if (filterPeriod === FilterPeriod.ThisMonth) {
        periodContent = moment().format("MMMM");
    }

    const spentPercentage = totalSum / categoriesTotalPlanned * 100;

    let plannedSection = <span
        style={{ fontSize: 16 }}>/ plan {categoriesTotalPlanned} / {spentPercentage.toFixed(2)}%</span>;
    if (!isFiltersDefault)
        plannedSection = <span style={{ fontSize: 18, color: "#ababab" }}>/ no plan</span>;

    return (
        <Wrapper>
            <TotalSumWrapper>{utils.formatSpentAmount(totalSum)} {plannedSection}</TotalSumWrapper>
            <PeriodWrapper>
                Expenses for <span style={{ fontWeight: "bold" }}>{periodContent}</span>
            </PeriodWrapper>
        </Wrapper>
    );
}
