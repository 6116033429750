
export enum AppTheme {
    Light = 1,
    Dark
}

export const colorIncome = "green";
export const colorExpense = "#b4521d";

export const dateFormat = "YYYY.MM.DD";
export const dateTimeFormat = "YYYY.MM.DD HH:mm";

export const pageMainSpacing = 15;
