import React from "react";
import {colorExpense, colorIncome} from "../styles/theme";

const formatIncome = (value: number, applyStyle: boolean = true) => {
    const isIncome = value > 0;
    const textValue = `${isIncome ? "+" : ""}${value} EUR`;

    if(!applyStyle) {
        return <span>{textValue}</span>
    }

    return <span style={{
        color: isIncome ? colorIncome : colorExpense
    }}>{textValue}</span>;
}

const formatSpentAmount = (value: number) => {
    const isPositive = value > 0;
    const textValue = `${value} EUR`;

    return <span style={{
        color: isPositive ? colorExpense: colorIncome
    }}>{textValue}</span>;
}

export const utils = {
    formatIncome,
    formatSpentAmount
};


