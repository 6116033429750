import config from "../../config";
import { StateUser } from "../../states/userState";
import { BudgetlyApiClient } from "./budgetlyApiClient";
import { Routes } from "../../models/RoutingUrls";

const client = new BudgetlyApiClient(config.apiUrl, {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
        const auth = StateUser.$userAuth.getState();
        const initConfig = <RequestInit>{ ...init };

        if (auth && auth.token)
            initConfig.headers = { ...initConfig.headers, "Authorization": `Bearer ${auth.token}` }

        return fetch(url, initConfig).then(r => {
            if (r.status === 401) {
                StateUser.logout();
            } else if (r.status === 403) {
                window.location.pathname = Routes.Forbidden;
            }
            return r;
        });
    }
});

export const ApiClient = client;
