import * as React from 'react';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { styled } from '@mui/system';
import { Box, Flex, Input } from '@chakra-ui/react';
import { useStore } from 'effector-react';
import { $categories, ICategory } from '../states/categoriesState';
import { FC, useState } from 'react';
import Autocomplete from 'react-autocomplete';


const Listbox = styled('ul')(({ theme }) => ({
    width: 300,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: '#fff',
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    borderRadius: 5,

    'li': {
        padding: 5
    },

    '& li.Mui-focused': {
        backgroundColor: '#f8f8f8',
        cursor: 'pointer',
    },
    '& li:active': {
        backgroundColor: '#467ace',
        color: 'white',
    },
}));

const InputStyled = styled('input')(({ theme }) => ({
    width: 200,
    backgroundColor: '#fff',
    color: '#000',
}));

interface IProps {
    id: string,
    name: string,
    onChange: (e: any) => void,
    onSelect: (e: string) => void,
    value: string | undefined
}

const CategoriesInput: FC<IProps> = ({
    id,
    name,
    onChange,
    onSelect,
    value
}) => {
    const categories = useStore($categories);

    return (
        <Autocomplete
            wrapperStyle={{ width: "100%" }}
            getItemValue={(item: ICategory) => item.name!}
            renderMenu={(items, value, style) => {
                return <div
                    style={{
                        ...style,
                        backgroundColor: "white",
                        position: "absolute",
                        padding: "10px 0",
                        zIndex: 10000,
                        borderRadius: 10,
                        border: "1px solid #dddddd",
                        maxHeight: "150px",
                        overflowY: "scroll"
                    }} children={items} />;
            }}
            shouldItemRender={(item: ICategory, value) => item.name!.toLowerCase().indexOf(value.toLowerCase()) > -1}
            items={categories}
            renderInput={(props: any) => {
                delete props.as;

                const onHandlers: any = {};
                for (const key in props) {
                    if (key.startsWith("on")) {
                        onHandlers[key] = props[key];
                    }
                }

                return <Input id={id} name={name} w="100%" {...props} onFocus={(e) => {
                    e.target.select();
                }} />;
            }}
            renderItem={(item: ICategory, isHighlighted: boolean) =>
                <Box p="5px 10px " key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                    {item.name}
                </Box>
            }
            value={value}
            onChange={onChange}
            onSelect={onSelect}
        />
    );
}

export default CategoriesInput;