import React, { FC } from "react";
import styled from "styled-components";
import { IPropsBase } from "../types";

const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 300px;
`;

export const CenteredForm: FC<IPropsBase> = ({ children }) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
}

