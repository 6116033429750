import React, { FC, useEffect, useState } from "react";
import { AutoComplete, Button, Checkbox, DatePicker, Drawer, Form, Input, InputNumber, Popconfirm, Select } from "antd";
import { useStore } from "effector-react";
import {
    $editingCategoryPlan,
    $isCategoryPlanEditorShown, ICategoryPlan, saveCategoryPlanFx, setEditingCategoryPlan,
    showCategoryPlanEditor
} from "../states/categoryPlanEditorState";
import { PriceInput } from "../pages/Expenses/PriceInput";
import styled from "styled-components";
import { deleteCategoryFx, updateCategoryFx } from "../states/categoriesState";
import confirm from "antd/lib/modal/confirm";

const PriceWrapper = styled.div`
  margin-top: 10px;
`

export const CategoryPlanDrawer: FC = () => {
    const isDrawerShown = useStore($isCategoryPlanEditorShown);
    const editingCategoryPlan = useStore($editingCategoryPlan);
    const [categoryInfo, setCategoryInfo] = useState<ICategoryPlan | null>(null);

    console.log(editingCategoryPlan);


    useEffect(() => {
        setCategoryInfo(editingCategoryPlan);
    }, [editingCategoryPlan]);

    const onFiltersClose = () => {
        showCategoryPlanEditor(false);
        setEditingCategoryPlan(null);
    };

    const onApplyFilters = async () => {
        showCategoryPlanEditor(false);
        if (categoryInfo) {
            await saveCategoryPlanFx(categoryInfo);
        }

        await updateCategoryFx({
            id: categoryInfo?.categoryId,
            name: categoryInfo?.categoryName,
            isArchived: categoryInfo?.isArchived || false
        })
    };

    const onDelete = async () => {
        showCategoryPlanEditor(false);
        await deleteCategoryFx(categoryInfo?.categoryId || "");
    };

    return (
        <Drawer
            height={350}
            title={`Edit "${categoryInfo?.categoryName}"`}
            placement={"bottom"}
            onClose={onFiltersClose}
            visible={isDrawerShown}
            closable={true}>

            <Button type={"primary"} danger onClick={() => {
                confirm({
                    content: "Delete category?",
                    async onOk() {
                        await onDelete();
                    },
                    centered: true
                })
            }} style={{ marginBottom: "20px", width: "100%" }}>
                Delete
            </Button>

            <Input value={categoryInfo?.categoryName}
                onFocus={(e) => {
                    e.target.select();
                }}
                onChange={(e) => {
                    if (!categoryInfo)
                        return;
                    setCategoryInfo({ ...categoryInfo, categoryName: e.target.value })
                }}
                placeholder={"New category name"} />

            <PriceWrapper>
                <span style={{ marginRight: 10 }}>Limit for this month</span>

                <PriceInput
                    onChange={(e) => {
                        if (categoryInfo)
                            setCategoryInfo({ ...categoryInfo, limit: e.number! })
                    }}
                    showCurrency={false}
                    value={{
                        currency: "eur",
                        number: categoryInfo?.limit
                    }} />
            </PriceWrapper>

            <Checkbox style={{ marginTop: 20, marginBottom: 5 }} checked={categoryInfo?.isArchived} onChange={(e) => {
                if (!categoryInfo)
                    return;

                const isChecked = e.target.checked;

                setCategoryInfo({ ...categoryInfo, isArchived: isChecked })
            }}>Is archived</Checkbox>

            <Button type={"primary"} onClick={onApplyFilters} style={{ marginTop: "20px", width: "100%" }}>
                Apply
            </Button>
        </Drawer>
    );
}
