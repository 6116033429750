import { createEffect, createStore, forward } from "effector";
import { expenseService } from "../services/expenseService";
import { saveCategoryPlanFx } from "./categoryPlanEditorState";

export interface IPlannedCategory {
    id: string;
    name: string;
    planned?: number;
    spent?: number;
    isArchived: boolean;
}

export interface ICategory {
    id?: string,
    name?: string,
    isArchived?: boolean
}

export const $categories = createStore<ICategory[]>([]);
export const $plannedCategories = createStore<IPlannedCategory[]>([]);
export const $categoriesTotalSpent = createStore<number>(0);
export const $categoriesTotalPlanned = createStore<number>(0);

export const loadCategoriesFx = createEffect({
    handler: async () => {
        try {
            const response = await expenseService.getCategories(null);
            if (!response.ok || !response.data)
                throw Error("Unable to load categories.");

            return response.data;
        } catch (error) {
            console.error("Error occurred while getting categories.", error);
            return null;
        }
    },
});

export const updateCategoryFx = createEffect({
    handler: async ({ name, id, isArchived }: ICategory) => {
        try {
            const response = await expenseService.updateCategory(id || "", name || "", isArchived || false);
            if (!response.ok || !response.data)
                throw Error("Unable to load categories.");

            return response.data;
        } catch (error) {
            console.error("Error occurred while getting categories.", error);
            return [];
        }
    },
});

export const createCategoryFx = createEffect({
    handler: async () => {
        try {
            const response = await expenseService.createCategory();
            if (!response.ok || !response.data)
                throw Error("Unable to create category.");

            return response.data;
        } catch (error) {
            console.error("Error occurred while category creating.", error);
            return [];
        }
    },
});

export const deleteCategoryFx = createEffect({
    handler: async (id: string) => {
        try {
            const response = await expenseService.deleteCategory(id);
            if (!response.ok || !response.data)
                throw Error("Unable to delete category.");

            return response.data;
        } catch (error) {
            console.error("Error occurred while category deleting.", error);
            return [];
        }
    },
});

$categories.on(loadCategoriesFx.doneData, (s, p) => p?.categories?.map(x => {
    const category: ICategory = {
        id: x.id,
        name: x.name || "",
        isArchived: x.isArchived || false
    }

    return category;
}));

$plannedCategories.on(loadCategoriesFx.doneData, (s, p) => p?.categories?.map(x => {
    const plannedCategory: IPlannedCategory = {
        id: x.id || "",
        name: x.name || "",
        planned: x.plan?.limit,
        spent: typeof x.expenses === 'number' ? -x.expenses : undefined,
        isArchived: x.isArchived || false
    };

    return plannedCategory;
}));

$categoriesTotalSpent.on(loadCategoriesFx.doneData, (s, p) => p?.totalSpent);
$categoriesTotalPlanned.on(loadCategoriesFx.doneData, (s, p) => p?.totalPlanned);

forward({
    from: [saveCategoryPlanFx.doneData, updateCategoryFx.doneData, createCategoryFx.doneData, deleteCategoryFx.doneData],
    to: loadCategoriesFx
})
