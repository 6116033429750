import { useStore } from "effector-react";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useLocation, useNavigation } from "react-router-dom";
import { StateExpenses } from "../../states/expenseState";
import { AddExpense } from "./AddExpense";

type IParams = {
    id: string;
}

export const EditExpense = (): JSX.Element => {
    const { id } = useParams<IParams>();
    const location = useLocation();

    const editingExpense = useStore(StateExpenses.$editingExpense);
    const isGetExpenseFxLoading = useStore(StateExpenses.$isGetExpenseFxLoading);

    useEffect(() => {
        StateExpenses.getExpenseFx(id || "");
    }, []);

    if (isGetExpenseFxLoading || editingExpense === null) {
        return <div>Loading...</div>;
    }

    return (
        <AddExpense editId={id} expense={editingExpense} />
    );
};