import { useStore } from "effector-react";
import React, { useEffect } from "react";
import { PageTitle } from "../../components/PageTitle";
import { IExpenseModel } from "../../models/models";
import { StateExpenses } from "../../states/expenseState";
import { TotalSummary } from "../../components/TotalSummary";
import { ExpenseItem } from "../../components/ExpenseItem";
import { ExpensesFloatingActions } from "../../components/ExpensesFloatingActions";
import { $filters } from "../../states/filtersState";
import { $categories } from "../../states/categoriesState";
import { Box } from "@chakra-ui/react";
import { AutoComplete } from "antd";

export interface IExpenseItem extends IExpenseModel {
    key: string;
}

export const Expenses = (): JSX.Element => {
    const filters = useStore($filters);
    const categories = useStore($categories);
    const categoryName = categories.find(x => x.id === filters.categoryId);

    const expenses = useStore(StateExpenses.$currentExpenses);

    useEffect(() => {
        StateExpenses.loadCurrentExpensesFx(filters);
    }, [filters]);

    return (
        <Box overflowY="scroll" w="100%">
            <ExpensesFloatingActions />

            <TotalSummary />

            <PageTitle>{
                categoryName && categoryName.name ? `"${categoryName.name}" expenses` : "All expenses"
            }</PageTitle>

            <Box pb={10}>
                {expenses.map((exp) => <ExpenseItem key={exp.id} {...exp} />)}
            </Box>
        </Box>
    );
};
