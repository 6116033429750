import { Flex, Button, ButtonGroup, Spacer } from "@chakra-ui/react";
import { useStore } from "effector-react";
import React from "react";
import { FC } from "react";
import { Link, NavLink } from "react-router-dom";
import { Routes } from "../models/RoutingUrls";
import { StateUser } from "../states/userState";


const Footer: FC = () => {
    const userAuth = useStore(StateUser.$userAuth);

    return (
        <Flex bgColor="#efefef9f" backdropFilter="auto" backdropBlur="6px" w="100%" pos={"fixed"} bottom={0} p={3}>
            {
                userAuth ?
                    <>
                        <ButtonGroup>
                            <Link to={Routes.Expenses}>
                                <Button size={"sm"} colorScheme={"green"}>Expenses</Button>
                            </Link>
                            <Link to={Routes.Summary}>
                                <Button size={"sm"} colorScheme={"teal"}>Categories</Button>
                            </Link>

                        </ButtonGroup>
                        <Spacer />
                        <Button size={"sm"} colorScheme={"blackAlpha"} onClick={() => StateUser.logout()} >Logout</Button>
                    </>
                    :
                    <Link to={Routes.Login}>
                        <Button size={"sm"}>Login</Button>
                    </Link>
            }
        </Flex>
    );
}

export default Footer;