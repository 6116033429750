import { createEvent, restore } from 'effector';
import { useStore } from 'effector-react';
import { createBrowserHistory, Location } from 'history';

const Urls = {
  Root: "/",
  Login: "/login",
  Dashboard: "/dashboard",
  Summary: "/categories",
  Earnings: "/earnings",
  Expenses: "/expenses",
  AddExpense: "/expenses/add",
  EditExpense: "/expenses/edit",
  Account: "/account",
  // SignUp: "/sign-up",
  // Explore: "/explore",
  // ViewUserProfile: "/user",
  // MyHowtos: "/my",
  // ViewHowto: "/explore",
  // Impressum:  "/impressum",
  // Terms: "/terms",
  NotFound: "/404",
  Forbidden: "/403",
  InternalError: "/500",
}

export const Routes = {
  ...Urls
};
