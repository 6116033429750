import { createEffect, createEvent, createStore, forward, restore, sample } from "effector";
import { expenseService } from "../services/expenseService";
import { ICategoryTotal as ICategorySummary, IExpenseState, ICategoriesSummary, IExpenseSummary } from "./types";
import { $filters } from "./filtersState";
import { FilterPeriod } from "../services/api/budgetlyApiClient";
import moment from "moment";
import { StateUser } from "./userState";

const applyFilters = createEvent();

const $currentExpenses = createStore<IExpenseState[]>([]);
const $summary = createStore<ICategoriesSummary | null>(null);
const $editingExpense = createStore<IExpenseState | null>(null);
const $saldo = createStore<number>(0);

const updateExpense = createEvent();

export interface IFilters {
    period: FilterPeriod,
    categoryId?: string,
    dateStart?: moment.Moment,
    dateEnd?: moment.Moment
}

const loadCurrentExpensesFx = createEffect({
    handler: async ({ period, categoryId, dateStart, dateEnd }: IFilters) => {
        try {
            const response = await expenseService.getCurrent(period, categoryId, dateStart && moment(dateStart).utc().toDate(), dateEnd && moment(dateEnd)?.utc().toDate());
            if (!response.ok || !response.data)
                throw Error("Unable to load current expenses.");

            const result: IExpenseSummary = {
                expenses: response.data.expenses!.map((x) => {
                    const r: IExpenseState = {
                        id: x.id || "",
                        amount: x.amount !== undefined ? x.amount : Number.NaN,
                        currency: x.currency || "",
                        categoryId: x.categoryDto?.id || "",
                        categoryName: x.categoryDto?.name || "",
                        description: x.description || "",
                        recordDate: x.utcRecorded || new Date(),
                    };
                    return r;
                }),
                saldo: response.data.saldo!
            };

            return result;
        } catch (error) {
            console.error("Error occurred while getting current expenses.", error);
            return null;
        }
    },
});

const setIsGetExpenseFxLoading = createEvent<boolean>();
const $isGetExpenseFxLoading = restore(setIsGetExpenseFxLoading, false);

const getExpenseFx = createEffect({
    handler: async (id: string) => {
        try {
            setIsGetExpenseFxLoading(true);
            const response = await expenseService.getExpense(id);
            if (!response.ok || !response.data)
                throw Error("Unable to get expense.");

            return response.data;
        } catch (error) {
            console.error("Error occurred while getting expense.", error);
            return null;
        } finally {
            setIsGetExpenseFxLoading(false);
        }
    },
});

const deleteExpenseFx = createEffect({
    handler: async (id: string) => {
        try {
            const response = await expenseService.deleteExpense(id);
            if (!response.ok)
                throw Error("Unable to delete.");
        } catch (error) {
            console.error("Error occurred while getting expense.", error);
        }
    },
});

$editingExpense.on(getExpenseFx.doneData, (s, p) => {
    if (p === null)
        return null;

    return {
        id: p.id || "",
        description: p.description || "",
        amount: p.amount || 0,
        currency: p.currency || "",
        categoryId: p.categoryId || "",
        recordDate: p.utcRecorded || new Date(),
        categoryName: p.category?.name || "",
    }
});

$editingExpense.watch(e => console.log('expense changed', e));

export interface ICreateExpenseArgs {
    amount: number,
    currency: string,
    category: string;
    categoryId?: string;
    description: string,
    recordedAt: Date;
}

const createExpenseFx = createEffect({
    handler: async (args: ICreateExpenseArgs) => {
        try {
            const response = await expenseService.create(args.amount, args.currency, args.category, args.categoryId, args.description, args.recordedAt);
            if (!response.ok || !response.data)
                throw Error("Unable to create expense.");

            const result: IExpenseState = {
                id: response.data.id || "",
                amount: response.data.amount !== undefined ? response.data.amount : Number.NaN,
                description: response.data.description || "",
                currency: response.data.currency || "",
                categoryName: response.data.category?.name || "",
                categoryId: response.data.categoryId || "",
                recordDate: response.data.utcRecorded || new Date()
            }

            return result;
        } catch (error) {
            console.error("Error occurred while getting current expenses", error);
            return null;
        }
    },
});

export interface IUpdateExpenseArgs {
    id: string,
    amount: number,
    currency: string,
    category: string;
    categoryId: string | null;
    description: string,
    recordedAt: Date;
}

const updateExpenseFx = createEffect({
    handler: async (args: IUpdateExpenseArgs) => {
        try {
            const response = await expenseService.update(args.id, args.amount, args.currency, args.category, args.description, args.recordedAt);
            if (!response.ok || !response.data)
                throw Error("Unable to update expense.");

            const result: IExpenseState = {
                id: response.data.id || "",
                amount: response.data.amount !== undefined ? response.data.amount : Number.NaN,
                description: response.data.description || "",
                currency: response.data.currency || "",
                categoryId: response.data.categoryId || "",
                categoryName: response.data.category?.name || "",
                recordDate: response.data.utcRecorded || new Date()
            }

            return result;
        } catch (error) {
            console.error("Error occurred while updating expenses", error);
            return null;
        }
    },
});

sample({
    source: $editingExpense,
    clock: updateExpense,
    fn: (p) => {
        const updateArgs: IUpdateExpenseArgs = {
            id: p?.id || "",
            amount: p?.amount || Number.NaN,
            category: p?.categoryName || "",
            categoryId: p?.categoryId || null,
            currency: p?.currency || "",
            description: p?.description || "",
            recordedAt: p?.recordDate || new Date()
        };

        return updateArgs;
    },
    target: updateExpenseFx
})

$currentExpenses
    .on(loadCurrentExpensesFx.doneData, (s, p) => p?.expenses)
    .reset(createExpenseFx.doneData);

$saldo.on(loadCurrentExpensesFx.doneData, (s, p) => p?.saldo);

sample({
    source: $filters,
    clock: [applyFilters, createExpenseFx.doneData],
    target: loadCurrentExpensesFx
})

sample({
    source: $filters,
    clock: [StateUser.loginFx.doneData],
    target: loadCurrentExpensesFx
})

export const StateExpenses = {
    $currentExpenses,
    $summary,
    $editingExpense,

    updateExpense,

    loadCurrentExpensesFx,
    $isGetExpenseFxLoading,
    getExpenseFx,
    createExpenseFx,
    updateExpenseFx,
    deleteExpenseFx,

    $saldo,

    applyFilters
};
